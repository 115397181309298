module.exports = [
  {
    title: "Vue.js/Vue3",
    icon: "icon-vue",
    level: "Fluent",
  },
  {
    title: "Nuxt.js/Nuxt3",
    icon: "icon-nuxt",
    level: "Fluent",
  },
  {
    title: "Vuex/Pinia",
    icon: "icon-vue",
    level: "Fluent",
  },
  {
    title: "React Redux & Hooks",
    icon: "icon-redux",
    level: "Fluent",
  },
  {
    title: "React.js",
    icon: "icon-react",
    level: "Fluent",
  },
  {
    title: "SEO & Web Performance",
    icon: "icon-earth2",
    level: "Fluent",
  },
  {
    title: "Javascript/Typescript",
    icon: "icon-javascript",
    level: "Fluent",
  },
  {
    title: "Web3",
    icon: "icon-web3",
    level: "Fluent",
  },
  {
    title: "Viem/Ethers.js",
    icon: "icon-web3",
    level: "Fluent",
  },
  {
    title: "AWS",
    icon: "icon-amazonaws",
    level: "Fluent",
  },
  {
    title: "Wordpress",
    icon: "icon-wordpress",
    level: "Fluent",
  },
  {
    title: "Unit Testing",
    icon: "icon-jest",
    level: "Fluent",
  },
  {
    title: "Vuetify/TailwindCSS/Bootstrap",
    icon: "icon-bootstrap",
    level: "Fluent",
  },
  {
    title: "Github",
    icon: "icon-github",
    level: "Fluent",
  },
  {
    title: "Bitbucket",
    icon: "icon-bitbucket",
    level: "Fluent",
  },
  {
    title: "SCSS/CSS",
    icon: "icon-css",
    level: "Fluent",
  },
  {
    title: "GSAP/Storybook",
    icon: "icon-storybook",
    level: "Fluent",
  },
  {
    title: "Blockchain",
    icon: "icon-web3",
    level: "Fluent",
  },
  {
    title: "Hardhat/Alchemy/Ganache",
    icon: "icon-web3",
    level: "Fluent",
  },
  {
    title: "Cloudflare + Web Deployment",
    icon: "icon-cloudflare",
    level: "Fluent",
  },
  {
    title: "Responsive Web Design",
    icon: "icon-responsive",
    level: "Fluent",
  },
  {
    title: "Smart Contract (Solidity)",
    icon: "icon-web3",
    level: "Familiar",
  },
  {
    title: "Laravel + Laravel Blade",
    icon: "icon-laravel",
    level: "Familiar",
  },
  {
    title: "MYSQL",
    icon: "icon-mysql",
    level: "Familiar",
  },
  {
    title: "Nginx/Apache",
    icon: "icon-nginx",
    level: "Fluent",
  },
  {
    title: "BrowserStack",
    icon: "icon-earth2",
    level: "Fluent",
  },
  {
    title: "MongoDB",
    icon: "icon-mongodb",
    level: "Familiar",
  },
  {
    title: "Express.js",
    icon: "icon-javascript",
    level: "Familiar",
  },
  {
    title: "Node.js",
    icon: "icon-node-js",
    level: "Familiar",
  },
  {
    title: "Firebase",
    icon: "icon-firebase",
    level: "Familiar",
  },
  {
    title: "Linux",
    icon: "icon-linux",
    level: "Fluent",
  },
  {
    title: "Fullstack Development",
    icon: "icon-code",
    level: "Familiar",
  },
  {
    title: "Frontend Development",
    icon: "icon-design",
    level: "Fluent",
  },
  {
    title: "Backend Development",
    icon: "icon-database",
    level: "Familiar",
  },
  {
    title: "PHP",
    icon: "icon-php",
    level: "Familiar",
  },
  {
    title: "Scrum",
    icon: "icon-team",
    level: "Fluent",
  },
  {
    title: "Interior Design",
    icon: "icon-design",
    level: "Fluent",
  },
  {
    title: "Graphic Design",
    icon: "icon-photoshop",
    level: "Fluent",
  },
  {
    title: "Teamwork",
    icon: "icon-team",
    level: "Fluent",
  },
];
